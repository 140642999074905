<template>
<ul class="menu-nav">
  <LinkMenu
    v-permission="['view_logs']"
    nameRoute="dashboardAdmin"
    :title="$t('PAGE.DASHBOARD')"
    icon="/media/svg/icons/Layout/Layout-grid.svg"
    class="mb-3"
    exact
  />
  <!--eCommerce START-->
  <template v-if="true">
    <li
      v-permission="['view_orders', 'view_clients', 'view_services', 'view_products']"
      class="menu-section m-0 mb-3"
    >
      <h4 class="menu-text">{{ $t('PAGE.ECOMMERCE_SEPARATE') }}</h4>
      <i class="menu-icon flaticon-more-v2" />
    </li>
    <LinkMenu
      v-permission="['view_orders']"
      nameRoute="companyOrdersIndex"
      :title="$t('PAGE.ORDERS')"
      icon="/media/svg/icons/Communication/Clipboard-list.svg"
    />
    <LinkMenu
      v-permission="['view_clients']"
      nameRoute="companyClientsIndex"
      :title="$t('PAGE.CLIENTS')"
      icon="/media/svg/icons/Communication/Adress-book2.svg"
    />
    <li
      v-permission="['view_services']"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      :class="{ 'menu-item-open': hasActiveChildren('/services') }"
    >
      <span class="menu-link menu-toggle">
        <span class="svg-icon menu-icon">
          <inline-svg src="/media/svg/icons/Tools/Roller.svg" />
        </span>
        <span class="menu-text">{{ $t('PAGE.SERVICES_GROUP') }}</span>
        <i class="menu-arrow" />
      </span>
      <div class="menu-submenu">
        <span class="menu-arrow" />
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">{{ $t('PAGE.SERVICES_GROUP') }}</span>
            </span>
          </li>
          <LinkMenu
            nameRoute="servicesListIndex"
            :title="$t('PAGE.SERVICES_LIST')"
            subLink
          />
          <LinkMenu
            v-permission="['view_clients']"
            nameRoute="servicesCategoriesIndex"
            :title="$t('PAGE.SERVICES_CATEGORIES')"
            subLink
          />
        </ul>
      </div>
    </li>

  </template>
  <!--eCommerce END-->
  <!--Company START-->
  <li
    v-permission="['view_employees', 'view_groups', 'view_branches', 'view_bot']"
    class="menu-section m-0 mb-3"
  >
    <h4 class="menu-text">
      {{ $t('PAGE.COMPANY_SEPARATE') }}
    </h4>
    <i class="menu-icon flaticon-more-v2" />
  </li>
  <li
    v-permission="['view_employees', 'view_groups']"
    aria-haspopup="true"
    data-menu-toggle="hover"
    class="menu-item menu-item-submenu"
    :class="{ 'menu-item-open': hasActiveChildren('/staff') }"
  >
    <span class="menu-link menu-toggle">
      <span class="svg-icon menu-icon">
        <inline-svg src="/media/svg/icons/Communication/Group.svg" />
      </span>
      <span class="menu-text">
        {{ $t('PAGE.STAFF') }}
      </span>
      <i class="menu-arrow" />
    </span>
    <div class="menu-submenu">
      <span class="menu-arrow" />
      <ul class="menu-subnav">
        <li
          aria-haspopup="true"
          class="menu-item menu-item-parent"
        >
          <span class="menu-link">
            <span class="menu-text">
              {{ $t('PAGE.STAFF') }}
            </span>
          </span>
        </li>
        <LinkMenu
          v-permission="['view_employees']"
          nameRoute="businessUser"
          :title="$t('PAGE.EMPLOYEES')"
          subLink
        />
        <LinkMenu
          v-permission="['view_groups']"
          nameRoute="roleBusinessUser"
          :title="$t('PAGE.ROLES_EMPLOYEES')"
          subLink
        />
      </ul>
    </div>
  </li>
  <LinkMenu
    v-permission="['view_branches']"
    nameRoute="companyBranchesIndex"
    :title="$t('PAGE.BRANCHES')"
    icon="/media/svg/icons/Map/Marker2.svg"
  />
  <is-granted
    :attributes="[
      'view_service_category_sums_by_orders',
      'view_num_services_by_category',
      'view_num_services_by_branch',
      'view_sums_by_orders',
    ]"
  >
    <LinkMenu
      :title="$t('PAGE.REPORTS_GROUP')"
      nameRoute="companyReportIndex"
      icon="/media/svg/icons/Shopping/Chart-pie.svg"
    />
    <li
      v-permission="['manage_settings', 'view_bot']"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      :class="{ 'menu-item-open': hasActiveChildren('/staff') }"
    >
      <span class="menu-link menu-toggle">
        <span class="svg-icon menu-icon">
          <inline-svg src="/media/svg/icons/General/Settings-1.svg" />
        </span>
        <span class="menu-text">
          {{ $t('PAGE.SETTINGS') }}
        </span>
        <i class="menu-arrow" />
      </span>
      <div class="menu-submenu">
        <span class="menu-arrow" />
        <ul class="menu-subnav">
          <li
            aria-haspopup="true"
            class="menu-item menu-item-parent"
          >
            <span class="menu-link">
              <span class="menu-text">
                {{ $t('PAGE.STAFF') }}
              </span>
            </span>
          </li>
          <LinkMenu
            v-permission="['manage_settings']"
            nameRoute="companySettingsEdit"
            :title="$t('PAGE.COMMON')"
            subLink
          />
          <LinkMenu
            v-permission="['view_bot']"
            nameRoute="companyBotIndex"
            :title="$t('PAGE.BOTS')"
            subLink
          />
          <LinkMenu
            v-permission="['view_bot']"
            nameRoute="companyTranslationsIndex"
            :title="$t('PAGE.TRANSLATIONS')"
            subLink
          />
        </ul>
      </div>
    </li>
  </is-granted>
  <li
    v-permission="['view_templates']"
    aria-haspopup="true"
    data-menu-toggle="hover"
    :class="{ 'menu-item-open': hasActiveChildren('/staff') }"
    class="menu-item menu-item-submenu"
  />
  <!--Company END-->
  <!--Stock START -->
  <!-- eslint-disable -->
  <template>
    <li class="menu-section m-0 mb-3">
      <h4 class="menu-text">{{ $t('PAGE.STOCK_SEPARATE') }}</h4>
      <i class="menu-icon flaticon-more-v2" />
    </li>
    <li
      v-permission="['view_products']"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu mb-3"
      :class="{ 'menu-item-open': hasActiveChildren('/products') }"
    >
      <span class="menu-link menu-toggle">
        <span class="svg-icon menu-icon">
          <inline-svg src="/media/svg/icons/Shopping/Box2.svg" />
        </span>
        <span class="menu-text">{{ $t('PAGE.PRODUCTS_GROUP') }}</span>
        <i class="menu-arrow" />
      </span>
      <div class="menu-submenu">
        <span class="menu-arrow" />
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">{{ $t('PAGE.PRODUCTS_GROUP') }}</span>
            </span>
          </li>
          <LinkMenu
            nameRoute="productsListIndex"
            :title="$t('PAGE.PRODUCTS_LIST')"
            subLink
          />
          <LinkMenu
            nameRoute="productsCategoriesIndex"
            :title="$t('PAGE.PRODUCTS_CATEGORIES')"
            subLink
          />
          <!--          <LinkMenu-->
          <!--            nameRoute="productsSupplierIndex"-->
          <!--            :title="$t('PAGE.PRODUCTS_SUPPLIER')"-->
          <!--            subLink-->
          <!--          />-->
        </ul>
      </div>
    </li>
    <LinkMenu
      nameRoute="companyWarehousesIndex"
      :title="$t('PAGE.WAREHOUSES')"
      icon="/media/svg/icons/Shopping/Barcode.svg"
    />
    <LinkMenu
      nameRoute="companySuppliersIndex"
      :title="$t('PAGE.SUPPLIERS')"
      icon="/media/svg/icons/Clothes/Briefcase.svg"
    />
    <LinkMenu
      nameRoute="companyStockEntryIndex"
      :title="$t('PAGE.STOCK_ENTRY')"
      icon="/media/svg/icons/Shopping/Wallet3.svg"
    />
  </template>
  <!--Stock END -->
</ul>
</template>

<script>
import LinkMenu from '../LinkMenu'

export default {
  name: 'KTMenu',
  components: { LinkMenu },
  methods: {
    hasActiveChildren(match) {
      return this.$route.path.indexOf(match) !== -1
    },
  },
}
</script>
