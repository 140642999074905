<template>
<div class="d-flex flex-column flex-root">
  <!-- begin:: Header Mobile -->
  <KTHeaderMobile />
  <!-- end:: Header Mobile -->

  <Loader
    v-if="loaderEnabled"
    :logo="loaderLogo"
  />

  <!-- begin::Body -->
  <div class="d-flex flex-row flex-column-fluid page">
    <!-- begin:: Aside Left -->
    <KTAside v-if="asideEnabled" />
    <!-- end:: Aside Left -->

    <div
      id="kt_wrapper"
      class="d-flex flex-column flex-row-fluid wrapper"
    >
      <!-- begin:: Header -->
      <KTHeader />
      <!-- end:: Header -->
      <PortalTarget name="subheader" />
      <!-- begin:: Content -->
      <div
        id="kt_content"
        class="content d-flex flex-column flex-column-fluid"
      >
        <!-- begin:: Content Body -->
        <div class="d-flex flex-column-fluid">
          <div
            :class="{
              'container-fluid': contentFluid,
              container: !contentFluid
            }"
            class="px-3 px-md-5"
          >
            <transition name="fade-in-up">
              <ErrorBoundary>
                <router-view />
              </ErrorBoundary>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
  <b-sidebar
    :visible="isQuickPanel"
    :width="checkIsMobile"
    right
    no-header
    no-close-on-backdrop
    no-close-on-esc
    bg-variant="white"
    backdrop-variant="light-dark"
    shadow
    backdrop
  >
    <div
      class="position-fixed w-100 bg-light zindex-1 py-3 px-3"
      style="height: 60px;"
    >
      <div class="d-flex justify-content-between align-items-center">
        <h5 class="text-truncate my-0">
          {{ getPanelTitle }}
        </h5>
        <div class="d-flex align-items-center justify-content-end">
          <div
            @click="$store.commit('onQuickPanelClose')"
            class="btn btn-icon mx-2"
          >
            <span class="svg-icon svg-icon-secondary svg-icon-lg">
              <inline-svg src="/media/svg/icons/Navigation/Close.svg" />
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="px-3 py-5"
      style="margin-top: 60px;"
    >
      <PortalTarget :name="getPanelName" />
    </div>
  </b-sidebar>
  <!--<KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar>-->
  <KTScrollTop />
  <modals-container />
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import KTAside from '@/view/layout/aside/company/Aside.vue'
import KTHeader from '@/view/layout/header/Header.vue'
import KTHeaderMobile from '@/view/layout/header/company/HeaderMobile.vue'
import HtmlClass from '@/core/services/htmlclass.service'
import KTScrollTop from '@/view/layout/extras/ScrollTop'
// import KTStickyToolbar from "@/view/layout/extras/company/StickyToolbar.vue";
import Loader from '@/view/content/Loader.vue'
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from '@/core/services/store/htmlclass.module.js'
import { isMobile } from 'mobile-device-detect'
import ErrorBoundary from '@/components/error-boundry'

export default {
  name: 'LayoutCompany',
  components: {
    ErrorBoundary,
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTScrollTop,
    // KTStickyToolbar,
    Loader,
  },
  computed: {
    ...mapGetters([
      'layoutConfig',
      'getPanelName',
      'isQuickPanel',
      'getPanelTitle',
      'getError',
    ]),
    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    checkIsMobile() {
      return isMobile ? '100%' : '400px'
    },
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'))
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig('content.width') === 'fluid'
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo')
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig('aside.self.display')
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig('toolbar.display');
      return true
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig('subheader.display')
    },
  },
  watch: {
    $route: {
      handler(router) {
        if (router.meta.view !== 'company') {
          if (router.path === '/') {
            this.$router.replace({
              name: 'dashboardCompany',
              params: this.$route.params,
              query: this.$route.query,
            })
          } else this.$router.push({ name: '404' })
        }
      },
      deep: true,
      immediate: true,
    },
    getError: {
      immediate: true,
      handler(val) {
        if (val?.status === 403) {
          this.$bvToast.toast(val.data.payload.details, {
            title: this.$t('TOAST.ERROR'),
            variant: 'danger',
            autoHideDelay: 2000,
            solid: true,
          })
          this.$store.commit('setErrorRequest', null)
        }
      },
    },
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, 'page-loading')

    // initialize html element classes
    HtmlClass.init(this.layoutConfig())
  },
  mounted() {
    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'page-loading')
    }, 1000)
  },
}
</script>
